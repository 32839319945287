import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Router, Routes ,Route } from 'react-router-dom';
import Navigation from './Navigation';
import Sidebar from './Sidebar';
import React from 'react';
import { Container } from 'react-bootstrap';

const Home = React.lazy(() => import('./Pages/Home'))
const FAQ = React.lazy(() => import('./Pages/FAQ'))
const Snapshot = React.lazy(() => import('./Pages/Snapshot'))

function App() {
  return (

    <div className="App" style={{
      backgroundColor: '#0C4160',
      width: '100%',
      height: '100%'
    }} id="outer-container">
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/FAQ' element={<FAQ/>}/>
        <Route exact path='/Snapshot' element={<Snapshot/>}/>
      </Routes>
    </div>

  );
}

export default App;
