import React from 'react';
import { bubble as Menu } from 'react-burger-menu';
import './Sidebar.css';
import { Link } from "react-router-dom";

export default props => {
  return (
    <Menu>
      <a className="menu-item" href="/">
        <h1 style={{fontSize:"2.5em"}}>Home</h1>
      </a>
      <a className="menu-item" href="/FAQ">
      <h1 style={{fontSize:"2.5em"}}>FAQ</h1>
      </a>
      <a className="menu-item" href="/Snapshot">
      <h1 style={{fontSize:"2.5em"}}>Snapshot</h1>
      </a>
    </Menu>
  );
};

// export default props => {
//     return (
//       <Menu>
//         <Link className="menu-item" to="/">
//           <h1 style={{fontSize:"40px"}}>Home</h1>
//         </Link>
//         <Link className="menu-item" to="/FAQ">
//         <h1 style={{fontSize:"40px"}}>FAQ</h1>
//         </Link>
//       </Menu>
//     );
//   };